import { Component, Inject } from '@angular/core'
import { EnvironmentService } from '../../../../../environments/environment.service'
import { Router } from '@angular/router'

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrl: './footer.component.scss',
})
export class FooterComponent {
	constructor(
		private environmentService: EnvironmentService,
		private router: Router,
	) {}
	goToMyRequests() {
		//verify if the user is logged in
		if (this.environmentService.isLoggedIn()) {
			this.router.navigate(['/history'])
			return
		}
		this.router.navigate(['/auth/login'])
	}
}
