<body>
	<div class="header">
		<app-navbar [mobile]="false"></app-navbar>
	</div>
	<div class="content-wrapper">
		<div class="navbar-content">
			<router-outlet></router-outlet>
		</div>
		<div class="footer md:block hidden">
			<app-footer></app-footer>
		</div>
	</div>
	<div class="header-mobile hidden">
		<app-navbar [mobile]="true"></app-navbar>
	</div>
</body>
