<div id="footer" class="hidden w-full md:flex justify-content-center">
	<div id="content" class="hidden">
		<div class="flex">
			<div>
				<span class="footer-header">{{
					'dashboard.footer.travels' | translate
				}}</span
				><br />
				<a href="#request-travel-ui"
					><span>{{
						'dashboard.footer.requestNewTravel' | translate
					}}</span
					><br
				/></a>
				<a href="#popular-routes-section"
					><span>{{
						'dashboard.footer.popularRoutes' | translate
					}}</span
					><br
				/></a>
				<a href="/map"
					><span>{{ 'dashboard.footer.map' | translate }}</span
					><br
				/></a>
			</div>
		</div>
		<div class="flex">
			<div>
				<span class="footer-header">{{
					'dashboard.footer.customerSupport' | translate
				}}</span
				><br />
				<a href="/contacts#request-travel-ui"
					><span>{{ 'dashboard.footer.help' | translate }}</span
					><br
				/></a>
				<a (click)="goToMyRequests()"
					><span>{{
						'dashboard.footer.manageTravel' | translate
					}}</span
					><br
				/></a>
				<a href="/report-problem"
					><span>{{
						'dashboard.footer.reportIssues' | translate
					}}</span
					><br
				/></a>
			</div>
		</div>
		<div class="flex">
			<div>
				<span class="footer-header">{{
					'dashboard.footer.termsAndConditions' | translate
				}}</span
				><br />
				<a href="/policy/faq"
					><span>{{ 'dashboard.footer.FAQ' | translate }}</span
					><br
				/></a>
				<!-- <a href="/policy/privacy"
					><span>{{
						'dashboard.footer.privacyPolicy' | translate
					}}</span
					><br
				/></a>
				<a href="/policy/cookies"
					><span>{{
						'dashboard.footer.cookiePolicy' | translate
					}}</span
					><br
				/></a>
				<a href="/policy/terms"
					><span>{{ 'dashboard.footer.termsOfUse' | translate }}</span
					><br
				/></a> -->
			</div>
		</div>
		<div class="flex">
			<div>
				<span class="footer-header">{{
					'dashboard.footer.contacts' | translate
				}}</span
				><br />
				<span>{{ 'contacts.footer.place' | translate }}</span
				><br />
				<span
					><a
						href="tel:{{
							'contacts.footer.phoneNumber' | translate
						}}"
					>
						{{ 'contacts.footer.phoneNumber' | translate }}
					</a></span
				><br />
				<span
					><a href="mailto:transportes@cm-castelobranco.pt">{{
						'contacts.footer.email' | translate
					}}</a></span
				>
			</div>
		</div>
	</div>
</div>
